import * as stakingABI from "./stakingABI";
import { formatPrice, formatPriceStandard } from "@/operations/formatUtils";
import * as utils from "../operations/utils";
import * as connexTransaction from "../operations/connexTransaction";

/* eslint-disable */
async function stakingInfo(
  connex: Connex,
  contractAddress: [string],
  address: string | null
) {
  address =
    address == null ? "0x0000000000000000000000000000000000000001" : address;

  // console.log(address);

  const secondPerDay = 86400;
  const transferClause = [] as any;

  let periodFinish = 0;
  let lastUpdateTime = 0;
  let rewardsDuration = 0;
  let rewardRate = 0;
  let rewardPerToken = 0;
  let totalSupplyStaked = 0;
  let totalRewardLeftOver = 0;

  let earned = 0;
  let balanceOf = 0;
  let ownerToTicket: [number] = [0];

  // get end of the staking period
  let method = connex.thor
    .account(contractAddress[0])
    .method(stakingABI.periodFinishABI);

  transferClause.push(method.asClause());

  // get last time the staking got updated/recalculated
  method = connex.thor
    .account(contractAddress[0])
    .method(stakingABI.lastUpdateTimeABI);

  transferClause.push(method.asClause());

  // get rewardsDuration
  method = connex.thor
    .account(contractAddress[0])
    .method(stakingABI.rewardsDurationABI);
  transferClause.push(method.asClause());

  //   get rewardRateCall
  method = connex.thor
    .account(contractAddress[0])
    .method(stakingABI.rewardRateABI);
  transferClause.push(method.asClause());

  // get rewardPerToken
  method = connex.thor
    .account(contractAddress[0])
    .method(stakingABI.rewardPerTokenABI);
  transferClause.push(method.asClause());

  //   get totalSupply
  method = connex.thor
    .account(contractAddress[0])
    .method(stakingABI.totalSupply);
  transferClause.push(method.asClause());

  // get address earned
  method = connex.thor.account(contractAddress[0]).method(stakingABI.earnedABI);
  transferClause.push(method.asClause(address));

  // get address balance
  method = connex.thor
    .account(contractAddress[0])
    .method(stakingABI.balanceOfABI);
  transferClause.push(method.asClause(address));

  // get ticket id
  method = connex.thor
    .account(contractAddress[0])
    .method(stakingABI.ownerToTicketABI);
  transferClause.push(method.asClause(address));

  const exp = connex.thor.explain(transferClause);
  const resp = await exp
    .gas(200000) // Set maximum gas
    //   .caller() // Set caller
    .execute()
    .then((outputs: string | any[]) => {
      // console.log(outputs);

      periodFinish = parseInt(outputs[0].data.slice(2).match(/.{1,64}/g), 16);
      lastUpdateTime = parseInt(outputs[1].data.slice(2).match(/.{1,64}/g), 16);
      rewardsDuration = parseInt(
        outputs[2].data.slice(2).match(/.{1,64}/g),
        16
      );
      rewardRate = parseInt(outputs[3].data.slice(2).match(/.{1,64}/g), 16);
      rewardPerToken = parseInt(outputs[4].data.slice(2).match(/.{1,64}/g), 16);
      totalSupplyStaked = parseInt(
        outputs[5].data.slice(2).match(/.{1,64}/g),
        16
      );
      totalSupplyStaked = totalSupplyStaked / Math.pow(10, 18);

      earned =
        parseInt(outputs[6].data.slice(2).match(/.{1,64}/g), 16) /
        Math.pow(10, 18);
      balanceOf =
        parseInt(outputs[7].data.slice(2).match(/.{1,64}/g), 16) /
        Math.pow(10, 18);
      ownerToTicket = [
        parseInt(outputs[8].data.slice(2).match(/.{1,64}/g), 16),
      ];

      const leftOverTimeInSecond = periodFinish - Date.now() / 1000;
      const leftReward = leftOverTimeInSecond * rewardRate;

      totalRewardLeftOver = leftReward / Math.pow(10, 18);
    });

  // ////// //
  // ////// //
  // ////// //
  const leftOverTimeInSecond = periodFinish - lastUpdateTime;
  let leftOverTime = leftOverTimeInSecond / secondPerDay;
  const leftReward = leftOverTimeInSecond * rewardRate;
  const interestRate =
    ((leftReward / Math.pow(10, 18)) * 100) /
    (totalSupplyStaked * (leftOverTime / 365)) /
    100;
  let powAPY = (Math.pow(1 + interestRate / 365, 365) - 1) * 100;
  console.log(powAPY);
  powAPY = powAPY.toString() == "NaN" ? 0 : powAPY;
  console.log(Date.now())
  const result = {
    periodFinish: periodFinish,
    lastUpdateTime: lastUpdateTime,
    rewardsDuration: rewardsDuration,
    rewardRate: rewardRate,
    rewardPerToken: rewardPerToken,
    totalSupplyStaked: totalSupplyStaked,
    totalRewardLeftOver: totalRewardLeftOver,
    earned: earned,
    balanceOf: balanceOf,
    ownerToTicket: ownerToTicket,
    APYconvert: formatPriceStandard(powAPY, 2),
  };
  // console.log(result);
  return result;
}

async function lastStakeInfo(
  connex: Connex,
  contractAddress: [string],
  address: string | null
) {
  address =
    address == null ? "0x0000000000000000000000000000000000000001" : address;

  // console.log(address);

  const secondPerDay = 86400;
  const transferClause = [] as any;

  let lastStake = 0;

  // get end of the staking period
  let method = connex.thor
    .account(contractAddress[0])
    .method({
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "lastStake",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    });

  transferClause.push(method.asClause(address));

  const exp = connex.thor.explain(transferClause);
  const resp = await exp
    .gas(200000) // Set maximum gas
    //   .caller() // Set caller
    .execute()
    .then((outputs: string | any[]) => {
      // console.log(outputs);

      lastStake = parseInt(outputs[0].data.slice(2).match(/.{1,64}/g), 16);
      // console.log(lastStake)
    });

  return lastStake;
}
async function ticketDetail(
  connex: Connex,
  contractAddress: string,
  chunkStart: number,
  chunkEnd: number
) {
  const ticketDetailABI = {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "ticketIdToticketDetails",
    outputs: [
      {
        internalType: "address",
        name: "ticketOwner",
        type: "address",
      },
      {
        internalType: "bool",
        name: "isExit",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  };

  const transferClause = [] as any;

  let periodFinish = 0;
  let lastUpdateTime = 0;
  let rewardsDuration = 0;
  let rewardRate = 0;
  let rewardPerToken = 0;
  let totalSupplyStaked = 0;
  let totalRewardLeftOver = 0;

  // get ticket details
  let method = connex.thor.account(contractAddress).method(ticketDetailABI);
  for (let index = chunkStart; index < chunkEnd; index++) {
    transferClause.push(method.asClause(index));
  }

  const exp = connex.thor.explain(transferClause);
  const resp = await exp
    .gas(200000) // Set maximum gas
    //   .caller() // Set caller
    .execute()
    .then((outputs: string | any[]) => {
      // console.log(outputs);
    });
}

async function stakeCall(
  connex: Connex,
  contractAddress: string,
  vip180Contract: string | null,
  address: string,
  amountStake: number,
  ticket: boolean | false
) {
  const evmStake = utils.humanToEVM(amountStake.toString());

  const transferClause = [] as any;

  let method;
  if (ticket) {
    method = connex.thor
      .account(contractAddress)
      .method(stakingABI.issueTicketABI);
    transferClause.push(method.asClause([], []));
  }
  if (vip180Contract != null) {
    method = connex.thor
      .account(vip180Contract)
      .method(stakingABI.increaseAllowanceABI);
    transferClause.push(method.asClause(contractAddress, evmStake));
  }

  method = connex.thor.account(contractAddress).method(stakingABI.stakeABI);
  transferClause.push(method.asClause(evmStake));

  // eslint-disable-next-line
  const result = await connexTransaction.feeDelegationSignTx(
    connex,
    null,
    null,
    address,
    transferClause,
    "stake wov"
  );
  return result;
}

async function getRewardCall(
  connex: Connex,
  contractAddress: string,
  address: string
) {
  const method = connex.thor
    .account(contractAddress)
    .method(stakingABI.getRewardABI);
  const transferClause = method.asClause();

  const result = await connexTransaction.feeDelegationSignTx(
    connex,
    null,
    null,
    address,
    [transferClause],
    "getReward"
  );
  return result;
}

async function withdrawCall(
  connex: Connex,
  contractAddress: string,
  address: string,
  amountStake: number
) {
  const evmStake = utils.humanToEVM(amountStake.toString());
  const method = connex.thor
    .account(contractAddress)
    .method(stakingABI.withdrawABI);
  const transferClause = method.asClause(evmStake);

  const result = await connexTransaction.feeDelegationSignTx(
    connex,
    null,
    null,
    address,
    [transferClause],
    "Withdraw"
  );

  return result;
}

export {
  lastStakeInfo,
  stakingInfo,
  ticketDetail,
  stakeCall,
  getRewardCall,
  withdrawCall,
};

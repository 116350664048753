













































































































































































































































import { Component, Vue } from "vue-property-decorator";



import Connex from "@vechain/connex";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { formatPrice, formatPriceStandard } from "./operations/formatUtils";

import SeashellStakeV3 from "./components/SeashellV3.vue";
import SeashellStakeV4 from "./components/SeashellV4.vue";
import DolphinStakeV2 from "./components/DolphinStakeV2.vue";
import DolphinStakeV3 from "./components/DolphinStakeV3.vue";

import * as vip180 from "./operations/vip180";

import * as stakingFunction from "./operations/stakingFunctions";

@Component({
  components: {
    SeashellStakeV3,
    SeashellStakeV4,
    DolphinStakeV2,
    DolphinStakeV3
  },
})
export default class App extends Vue {
  public formatPrice = formatPrice;
  public formatPriceStandard = formatPriceStandard;

  public component = 1;
  public totalSupplyWoV = 0;
  public totalValueStaked = 0;
  public totalValueLiquidity = 0;
  public wovUSD = 0;
  public signerAddress = "";
  public signerWoVBalance = 0;
  public genesisArray = [] as any;
  public specialArray = [] as any;
  public GENESIS_TOKEN_ADDRESS = "0x93Ae8aab337E58A6978E166f8132F59652cA6C56";
  public GENESIS_TOKEN_ADDRESS_SALE =
    "0x48908fD4B00B8e13bd172da9416B6fc40A6A737c";
  public SPECIAL_TOKEN_ADDRESS = "0x9aaB6e4e017964ec7C0F092d431c314F0CAF6B4B";
  public SPECIAL_TOKEN_ADDRESS_SALE =
    "0x0f5b7886A19adEE581987423Ac7678A2B3832D1F";
  public WOV_VIP180 = "0x170F4BA8e7ACF6510f55dB26047C83D13498AF8A";
  public VIP191SERVICE =
    "https://vip191service-stagging.worldofv.art/.netlify/functions/api/vip191service/";
  public VIP191PAYER = "0x5E07F021140578B5B7Be73C6621517740b9B7975";

  public genesisBalance = [] as any;
  public genesisStakedBalance = [] as any;
  public ogBalance = [] as any;
  public goldBalance = [] as any;
  public platinumBalance = [] as any;
  public xnodeBalance = [] as any;
  public voyagerBalance = [] as any;
  public moonBalance = [] as any;
  public africaBalance = [] as any;
  public olympicsBalance = [] as any;
  public whaleBalance = [] as any;
  public throphyBalance = [] as any;
  public flowerBalance = [] as any;
  public genesisVirtualBalance = 0;
  public specialVirtualBalance = 0;

  public XNODE_START_TOKENID = 0;
  public PLAT_START_TOKENID = 348;
  public GOLD_START_TOKENID = 548;
  public WHALE_START_TOKENID = 948;
  public AFRICA_START_TOKENID = 998;
  public MOON_START_TOKENID = 1048;
  public OLYMPIC_START_TOKENID = 1098;
  public FLOWER_START_TOKENID = 1148;
  public OG_START_TOKENID = 1448;
  public VOYAGER_START_TOKENID = 2992;
  public TROPHY_START_TOKENID = 3192;

  public selectComponent(index: number) {
    this.component = index;
  }

  public async totalSupply(address: string) {
    const ABI = {
      inputs: [],
      name: "totalSupply",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    };

    // eslint-disable-next-line
    const method = this.$connex.thor.account(address).method(ABI);
    const transfer = await method.call();
    return parseInt(transfer.decoded[0]);
  }

  public async totalValueStakedFunction() {
    let seashellValueV3 = await this.totalSupply(
      "0xcD95adeAA49140A004303e382C1140DfF0Db6027"
    );
    let seashellValueV4 = await this.totalSupply(
      "0x260555616B69FB46b7aa5bF1BD1a826EB4a81C66"
    );
    let dolphinValue = await this.totalSupply(
      "0xF6B3e7cfde716Ff52a1bb1d140701991559Ddb97"
    );
    let SharkValue = await this.totalSupply(
      "0x126C54213D3B51C706ddCA797c7AEB35A58Ebccd"
    );
    let newSeaShellValue = await this.totalSupply("0xEb2112F2a88Ff7395574B5eaCD5856f0200f4C6B")

    let dolphin2 = await this.totalSupply("0x0451F7CCAd9f6Ab00Af324557B0ad5D34B03032F")

    let dolphin3 = await this.totalSupply("0xDCA5fCaE196a774eA153761aC81b5ddEF984D807")

    
    //
    this.totalValueStaked = dolphinValue + SharkValue +newSeaShellValue + seashellValueV3 + dolphin2 + seashellValueV4 + dolphin3;
  }

  public async totalValueLiquidityFunction() {
    let vexPool = await this.getBalanceOf(
      this.WOV_VIP180,
      "0xD86bed355d9d6A4c951e96755Dd0c3cf004d6CD0"
    );
    let zumoPool = await this.getBalanceOf(
      this.WOV_VIP180,
      "0x94355b3079a38a265e6b7a825ab6a06495c2d419"
    );
    this.totalValueLiquidity = vexPool + zumoPool;
  }
  public redirect(string: string) {
    if (string === "genesis") {
      window.location.href = "https://worldofv.art/collection/genesis";
    } else if (string === "vex") {
      window.location.href =
        "https://vexchange.io/swap?outputCurrency=0x170F4BA8e7ACF6510f55dB26047C83D13498AF8A&inputCurrency=0xD8CCDD85abDbF68DFEc95f06c973e87B1b5A9997&exactAmount=10000";
    }
  }
  public unSync() {
    localStorage.removeItem("signerAddress");
    this.signerAddress = "";
  }

  // signAgreement
  public async signAgreement(): Promise<void> {
    const result = await this.$connex.vendor
      .sign("cert", {
        purpose: "identification",
        payload: {
          type: "text",
          content: "Please select a wallet and grant access to the dApp.",
        },
      })
      .link("https://staking.worldofv.art/")
      .request();

    // console.log(result);
    this.signerAddress = result.annex.signer;
    localStorage.signerAddress = result.annex.signer;
    this.signerWoVBalance = await vip180.balanceOf(
      this.$connex,
      this.WOV_VIP180,
      this.signerAddress
    );
    this.addressTokenList();
  }

  public async addressTokenList() {
    let genesisBalance = await this.getBalanceOf(
      this.GENESIS_TOKEN_ADDRESS,
      this.signerAddress
    );
    console.log(genesisBalance);
    this.genesisVirtualBalance = await this.getVirtualBalanceOf(
      "0x6b273FFfAe3b682bA9E62aDA2A052Ade9F2fC99C",
      this.signerAddress
    );

    this.specialVirtualBalance = await this.getVirtualBalanceOf(
      "0x3B9220CA69eCF589AB960437C2e742c305Fe4545",
      this.signerAddress
    );

    if(genesisBalance > 9){
      this.genesisVirtualBalance = genesisBalance;
    }

    let specialBalance = await this.getBalanceOf(
      this.SPECIAL_TOKEN_ADDRESS,
      this.signerAddress
    );
    const maxSize = 1000;
    let chunksSize = 0;
    console.log(specialBalance);
    if (specialBalance > 0) {
      if (specialBalance > maxSize) {
        chunksSize = maxSize;
      } else {
        chunksSize = specialBalance;
      }
      let chunks = this.chunkBy(specialBalance, chunksSize);
      console.log(chunks)
      let start = 0;
      let end = 0;
      for (let c = 0; c < chunks.length; c++) {
        end = end + chunks[c];
        const exp = await this.getMyCollection(
          this.SPECIAL_TOKEN_ADDRESS,
          this.signerAddress,
          start,
          end
        );
        await exp
          .gas(20000000) // Set maximum gas
          .caller(this.signerAddress) // Set caller
          .execute()
          .then((outputs: string | any[]) => {
            start = start + parseInt(chunks[c]);
            for (let i = 0; i < outputs.length; i++) {
              const match: RegExpMatchArray | null = outputs[i].data
                .slice(2)
                .match(/.{1,64}/g);
              if (match != null) {
                
                if (
                  this.specialArray.find(
                    (el: number) => el == parseInt(match[0], 16)
                  ) == undefined
                ) {
                  
                  this.specialArray.push(parseInt(match[0], 16));
                  // console.log(parseInt(match[0], 16));
                }
              }
            }
          });
      }
      start = 0;
      end = 0;
      for (let c = 0; c < chunks.length; c++) {
        end = end + chunks[c];
        const exp = await this.getMySales(
          this.SPECIAL_TOKEN_ADDRESS_SALE,
          start,
          end,
          this.specialArray
        );
        await exp
          .gas(20000000) // Set maximum gas
          .caller(this.signerAddress) // Set caller
          .execute()
          .then((outputs: string | any[]) => {
            for (let i = 0; i < outputs.length; i++) {
              const match: RegExpMatchArray | null = outputs[i].data
                .slice(2)
                .match(/.{1,64}/g);
              if (match != null) {
                let index = start + i;
                if (parseInt(match[0], 16) == 1) {
                  // console.log(this.specialArray[index]);
                  // REMOVE THIS PART BECAUSE SALES CHANGED
                  // this.specialArray[index] = -1;
                }
              }
            }
            start = start + parseInt(chunks[c]);
          });
      }
    }

    if(this.specialVirtualBalance > 0){
      if (this.specialVirtualBalance > maxSize) {
        chunksSize = maxSize;
      } else {
        chunksSize = this.specialVirtualBalance;
      }
      let chunks = this.chunkBy(this.specialVirtualBalance, chunksSize);
      console.log(chunks)
      let start = 0;
      let end = 0;
      for (let c = 0; c < chunks.length; c++) {
        end = end + chunks[c];
        const exp = await this.getMyStakedCollection(
          "0xFAbcE34bb0b1174f1e0127d69bb705C60c35e587",
          this.signerAddress,
          start,
          end
        );
        await exp
          .gas(20000000) // Set maximum gas
          .caller(this.signerAddress) // Set caller
          .execute()
          .then((outputs: string | any[]) => {
            start = start + parseInt(chunks[c]);
            for (let i = 0; i < outputs.length; i++) {
              const match: RegExpMatchArray | null = outputs[i].data
                .slice(2)
                .match(/.{1,64}/g);
              if (match != null) {
                
                if (
                  this.specialArray.find(
                    (el: number) => el == parseInt(match[0], 16)
                  ) == undefined
                ) {
                  
                  this.specialArray.push(parseInt(match[0], 16));
                  // console.log(parseInt(match[0], 16));
                }
              }
            }
          });
      }
    }
    if (genesisBalance > 0) {
      if (genesisBalance > maxSize) {
        chunksSize = maxSize;
      } else {
        chunksSize = genesisBalance;
      }
      let chunks = this.chunkBy(genesisBalance, chunksSize);
      let start = 0;
      let end = 0;
      for (let c = 0; c < chunks.length; c++) {
        end = end + chunks[c];
        const exp = await this.getMyCollection(
          this.GENESIS_TOKEN_ADDRESS,
          this.signerAddress,
          start,
          end
        );
        await exp
          .gas(20000000) // Set maximum gas
          .caller(this.signerAddress) // Set caller
          .execute()
          .then((outputs: string | any[]) => {
            start = start + parseInt(chunks[c]);
            for (let i = 0; i < outputs.length; i++) {
              const match: RegExpMatchArray | null = outputs[i].data
                .slice(2)
                .match(/.{1,64}/g);
              if (match != null) {
                if (
                  this.genesisArray.find(
                    (el: number) => el == parseInt(match[0], 16)
                  ) == undefined
                ) {
                  this.genesisArray.push(parseInt(match[0], 16));
                }
              }
            }
          });
      }

      start = 0;
      end = 0;
      for (let c = 0; c < chunks.length; c++) {
        end = end + chunks[c];
        const exp = await this.getMySales(
          this.GENESIS_TOKEN_ADDRESS_SALE,
          start,
          end,
          this.genesisArray
        );
        await exp
          .gas(200000) // Set maximum gas
          .caller(this.signerAddress) // Set caller
          .execute()
          .then((outputs: string | any[]) => {
            for (let i = 0; i < outputs.length; i++) {
              const match: RegExpMatchArray | null = outputs[i].data
                .slice(2)
                .match(/.{1,64}/g);
              if (match != null) {
                let index = start + i;
                if (parseInt(match[0], 16) == 1) {
                  // console.log(this.genesisArray[index]);
                  // REMOVE BECAUSE NO MORE THIS KIND OF SALES
                  // this.genesisArray[index] = -1;
                }
              }
            }
            start = start + parseInt(chunks[c]);
          });
      }
    }

    this.genesisArray = this.genesisArray.filter((element: string) => {
      return parseInt(element) > 0;
    });
    this.specialArray = this.specialArray.filter((element: string) => {
      return parseInt(element) > 0;
    });

    console.log(this.specialArray);
    this.specialArray.forEach((element: number) => {
      if (element >= this.TROPHY_START_TOKENID) {
        if (
          this.throphyBalance.find((el: number) => el == element) == undefined
        ) {
          this.throphyBalance.push(element);
        }
      } else if (element >= this.VOYAGER_START_TOKENID) {
        if (
          this.voyagerBalance.find((el: number) => el == element) == undefined
        ) {
          this.voyagerBalance.push(element);
        }
      } else if (element >= this.OG_START_TOKENID) {
        if (this.ogBalance.find((el: number) => el == element) == undefined) {
          this.ogBalance.push(element);
        }
      } else if (element >= this.FLOWER_START_TOKENID) {
        if (
          this.flowerBalance.find((el: number) => el == element) == undefined
        ) {
          this.flowerBalance.push(element);
        }
      } else if (element >= this.OLYMPIC_START_TOKENID) {
        if (
          this.olympicsBalance.find((el: number) => el == element) == undefined
        ) {
          this.olympicsBalance.push(element);
        }
      } else if (element >= this.MOON_START_TOKENID) {
        if (this.moonBalance.find((el: number) => el == element) == undefined) {
          this.moonBalance.push(element);
        }
      } else if (element >= this.AFRICA_START_TOKENID) {
        if (
          this.africaBalance.find((el: number) => el == element) == undefined
        ) {
          this.africaBalance.push(element);
        }
      } else if (element >= this.WHALE_START_TOKENID) {
        if (
          this.whaleBalance.find((el: number) => el == element) == undefined
        ) {
          this.whaleBalance.push(element);
        }
      } else if (element >= this.GOLD_START_TOKENID) {
        if (this.goldBalance.find((el: number) => el == element) == undefined) {
          this.goldBalance.push(element);
        }
      } else if (element >= this.PLAT_START_TOKENID) {
        if (
          this.platinumBalance.find((el: number) => el == element) == undefined
        ) {
          this.platinumBalance.push(element);
        }
      } else if (element >= this.XNODE_START_TOKENID) {
        if (
          this.xnodeBalance.find((el: number) => el == element) == undefined
        ) {
          this.xnodeBalance.push(element);
        }
      }
    });

    this.genesisBalance = this.genesisArray;
  }

  public async getMyCollection(
    addressContract: string,
    signerAddress: string,
    start: number,
    end: number
  ): Promise<Connex.VM.Explainer> {
    const transferClause = [] as any;

    // Prepare energy transfer clause

    const ABI = {
      inputs: [
        {
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "index",
          type: "uint256",
        },
      ],
      name: "tokenOfOwnerByIndex",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
      constant: true,
    };
    const transferMethod = this.$connex.thor
      .account(addressContract)
      .method(ABI);
    for (let index = start; index < end; index++) {
      transferClause.push(transferMethod.asClause(signerAddress, index));
    }
    return this.$connex.thor.explain(transferClause);
  }

  public async getMyStakedCollection(
    addressContract: string,
    signerAddress: string,
    start: number,
    end: number
  ): Promise<Connex.VM.Explainer> {
    const transferClause = [] as any;

    // Prepare energy transfer clause

    const ABI = {
      inputs: [
        {
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "index",
          type: "uint256",
        },
      ],
      name: "tokenOfOwnerByIndex",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
      constant: true,
    };
    const transferMethod = this.$connex.thor
      .account(addressContract)
      .method(ABI);
    for (let index = start; index < end; index++) {
      transferClause.push(transferMethod.asClause(signerAddress, index));
    }
    return this.$connex.thor.explain(transferClause);
  }
  public async getMySales(
    addressContract: string,
    start: number,
    end: number,
    tokenList: []
  ): Promise<Connex.VM.Explainer> {
    const transferClause = [] as any;

    // Prepare energy transfer clause
    const ABI = {
      inputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      name: "listedMap",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "view",
      type: "function",
    };

    const transferMethod = this.$connex.thor
      .account(addressContract)
      .method(ABI);
    for (let index = start; index < end; index++) {
      transferClause.push(transferMethod.asClause(tokenList[index]));
    }
    return this.$connex.thor.explain(transferClause);
  }

  public async getBalanceOf(
    addressContract: string,
    addressHolder: string
  ): Promise<number> {
    const ABI = {
      inputs: [
        {
          internalType: "address",
          name: "owner",
          type: "address",
        },
      ],
      name: "balanceOf",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    };

    // eslint-disable-next-line
    const method = this.$connex.thor.account(addressContract).method(ABI);
    const transfer = await method.call(addressHolder);
    return parseInt(transfer.decoded[0]);
  }
  public async getVirtualBalanceOf(
    addressContract: string,
    addressHolder: string
  ): Promise<number> {
    const ABI = {
      inputs: [
        {
          internalType: "address",
          name: "owner",
          type: "address",
        },
      ],
      name: "balanceNftStaked",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    };

    // eslint-disable-next-line
    const method = this.$connex.thor.account(addressContract).method(ABI);
    const transfer = await method.call(addressHolder);
    console.log(parseInt(transfer.decoded[0]));
    return parseInt(transfer.decoded[0]);
  }
  private chunkBy(number: number, n: number) {
    var chunks = Array(Math.floor(number / n)).fill(n);
    var remainder = number % n;
    if (remainder > 0) {
      chunks.push(remainder);
    }
    return chunks;
  }

  public async ticker(): Promise<void> {
    let tick = this.$connex.thor.ticker();
    tick.next().then(() => {
      this.ticker();
    });
  }

  public async created() {
    this.totalSupplyWoV = await this.totalSupply(this.WOV_VIP180);
    this.totalValueStakedFunction();
    this.totalValueLiquidityFunction();
    if (localStorage.signerAddress) {
      this.signerAddress = localStorage.signerAddress;
      this.addressTokenList();
    }

    // const backendURL = "https://api.marketplace.worldofv.art/blockchain/prices";

    // const wovInfo = await window
    //   .fetch(backendURL, { mode: "cors" })
    //   .then((response) => response.json())
    //   .catch((err) => {
    //     console.error(err);
    //     return err;
    //   });
    // console.log(wovInfo.WoV);
    // this.wovUSD = parseFloat(wovInfo[wovInfo.length - 1].price);
    // this.wovUSD = parseFloat(wovInfo.WoV);
    this.wovUSD = 0;

    if (this.signerAddress != "") {
      this.signerWoVBalance = await vip180.balanceOf(
        this.$connex,
        this.WOV_VIP180,
        this.signerAddress
      );
    }

    setInterval(async () => {
      if (this.signerAddress != "") {
        this.signerWoVBalance = await vip180.balanceOf(
          this.$connex,
          this.WOV_VIP180,
          this.signerAddress
        );
      }
    }, 4000);

    setInterval(async () => {
      if (this.signerAddress != "") {
        this.addressTokenList();
      }
      this.totalSupplyWoV = await this.totalSupply(this.WOV_VIP180);
      this.totalValueStakedFunction();
    }, 30000);
  }
}

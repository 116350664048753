/* eslint-disable */
async function feeDelegationSignTx(
  connex: Connex,
  service: string| null,
  payer: string | null,
  signer: string,
  transferClause: Connex.Vendor.TxMessage,
  comment: string
) {
  if (window.connex) {

    const signingService = connex.vendor.sign("tx", transferClause);
    signingService
    .signer(signer) // Enforce signer
    .comment(comment);

    // eslint-disable-next-line
    const transactionInfo = await signingService.request();
    return transactionInfo;

  } else {
    // Connex V2
    // eslint-disable-next-line
    const signingService = connex.vendor.sign("tx", transferClause);

    if (service == null || payer == null) {
      signingService
      .signer(signer) // Enforce signer
      .comment(comment);
    }else{
      signingService
      // .delegate(service + "V2/", payer)
      .signer(signer) // Enforce signer
      .comment(comment);
    }

    // eslint-disable-next-line
    const transactionInfo = await signingService.request();
    return transactionInfo;
  }
}

export { feeDelegationSignTx };

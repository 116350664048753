const TEN_POWER_18 = 1000000000000000000;

const formatPrice = (price: number, decimals = 0): string => {
  return Intl.NumberFormat('us-US', {
    maximumFractionDigits: decimals,
  }).format(price / TEN_POWER_18)
}

const formatPriceStandard = (price: number, decimals = 0): string => {
  return Intl.NumberFormat('us-US', {
    maximumFractionDigits: decimals,
  }).format(price)
}

export {formatPrice, formatPriceStandard};

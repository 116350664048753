const stakeABI = {
  inputs: [
    {
      internalType: "uint256",
      name: "amount",
      type: "uint256",
    },
  ],
  name: "stake",
  outputs: [],
  stateMutability: "nonpayable",
  type: "function",
};

const getRewardABI = {
  inputs: [],
  name: "getReward",
  outputs: [],
  stateMutability: "nonpayable",
  type: "function",
};

const withdrawABI = {
  inputs: [
    {
      internalType: "uint256",
      name: "amount",
      type: "uint256",
    },
  ],
  name: "withdraw",
  outputs: [],
  stateMutability: "nonpayable",
  type: "function",
};

const exitABI = {
  inputs: [],
  name: "exit",
  outputs: [],
  stateMutability: "nonpayable",
  type: "function",
};

const earnedABI = {
  inputs: [
    {
      internalType: "address",
      name: "account",
      type: "address",
    },
  ],
  name: "earned",
  outputs: [
    {
      internalType: "uint256",
      name: "",
      type: "uint256",
    },
  ],
  stateMutability: "view",
  type: "function",
};

const totalSupply = {
  inputs: [],
  name: "totalSupply",
  outputs: [
    {
      internalType: "uint256",
      name: "",
      type: "uint256",
    },
  ],
  stateMutability: "view",
  type: "function",
};

const periodFinishABI = {
  inputs: [],
  name: "periodFinish",
  outputs: [
    {
      internalType: "uint256",
      name: "",
      type: "uint256",
    },
  ],
  stateMutability: "view",
  type: "function",
};

const lastUpdateTimeABI = {
  inputs: [],
  name: "lastUpdateTime",
  outputs: [
    {
      internalType: "uint256",
      name: "",
      type: "uint256",
    },
  ],
  stateMutability: "view",
  type: "function",
};

const rewardsDurationABI = {
  inputs: [],
  name: "rewardsDuration",
  outputs: [
    {
      internalType: "uint256",
      name: "",
      type: "uint256",
    },
  ],
  stateMutability: "view",
  type: "function",
};

const rewardRateABI = {
  inputs: [],
  name: "rewardRate",
  outputs: [
    {
      internalType: "uint256",
      name: "",
      type: "uint256",
    },
  ],
  stateMutability: "view",
  type: "function",
};

const rewardPerTokenABI = {
  inputs: [],
  name: "rewardPerToken",
  outputs: [
    {
      internalType: "uint256",
      name: "",
      type: "uint256",
    },
  ],
  stateMutability: "view",
  type: "function",
};

const balanceOfABI = {
  inputs: [
    {
      internalType: "address",
      name: "account",
      type: "address",
    },
  ],
  name: "balanceOf",
  outputs: [
    {
      internalType: "uint256",
      name: "",
      type: "uint256",
    },
  ],
  stateMutability: "view",
  type: "function",
};

const increaseAllowanceABI = {
  inputs: [
    {
      internalType: "address",
      name: "spender",
      type: "address",
    },
    {
      internalType: "uint256",
      name: "addedValue",
      type: "uint256",
    },
  ],
  name: "increaseAllowance",
  outputs: [
    {
      internalType: "bool",
      name: "",
      type: "bool",
    },
  ],
  stateMutability: "nonpayable",
  type: "function",
};

const issueTicketABI = {
  inputs: [
    {
      internalType: "address[]",
      name: "_nft",
      type: "address[]",
    },
    {
      internalType: "uint256[]",
      name: "_tokenId",
      type: "uint256[]",
    },
  ],
  name: "issueTicket",
  outputs: [],
  stateMutability: "nonpayable",
  type: "function",
};

const closeTicketABI = {
  inputs: [],
  name: "closeTicket",
  outputs: [],
  stateMutability: "nonpayable",
  type: "function",
};

const approveForAllABI = {
  inputs: [
    {
      internalType: 'address',
      name: 'operator',
      type: 'address',
    },
    {
      internalType: 'bool',
      name: 'approved',
      type: 'bool',
    },
  ],
  name: 'setApprovalForAll',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
};

const ownerToTicketABI = {
  "inputs": [
    {
      "internalType": "address",
      "name": "",
      "type": "address"
    }
  ],
  "name": "ownerToTicket",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function",
  "constant": true
}

export {
  stakeABI,
  getRewardABI,
  withdrawABI,
  earnedABI,
  exitABI,
  totalSupply,
  periodFinishABI,
  lastUpdateTimeABI,
  rewardsDurationABI,
  rewardRateABI,
  rewardPerTokenABI,
  balanceOfABI,
  increaseAllowanceABI,
  issueTicketABI,
  closeTicketABI,
  approveForAllABI,
  ownerToTicketABI
};

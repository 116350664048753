
















































































































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { formatPrice, formatPriceStandard } from "@/operations/formatUtils";
import InfiniteLoading from "vue-infinite-loading";
import * as utils from "../operations/utils";

import * as connexTransaction from "../operations/connexTransaction";
import * as stakingABI from "../operations/stakingABI";
import StakingList from "@/types/StakingList";

import * as stakingFunction from "../operations/stakingFunctions";

import Countdown from "../components/Countdown.vue";

import { bus } from "@/main";

@Component({
  components: { InfiniteLoading, Countdown },
})
export default class DolphinStakeV3 extends Vue {
  @Prop() GENESIS_TOKEN_ADDRESS: string;
  @Prop() SPECIAL_TOKEN_ADDRESS: string;
  @Prop() WOV_VIP180: string;
  @Prop() VIP191SERVICE: string;
  @Prop() VIP191PAYER: string;
  @Prop() signerAddress!: string;
  @Prop() signerWoVBalance!: number;
  @Prop() genesisBalance!: [];
  @Prop() genesisVirtualBalance!: number;
  @Prop() ogBalance!: [];
  @Prop() goldBalance!: [];
  @Prop() platinumBalance!: [];
  @Prop() xnodeBalance!: [];
  @Prop() voyagerBalance!: [];
  @Prop() moonBalance!: [];
  @Prop() africaBalance!: [];
  @Prop() olympicsBalance!: [];
  @Prop() whaleBalance!: [];
  @Prop() throphyBalance!: [];
  @Prop() wovUSD!: number;

  public lastStakingTime = 0;
  public minimalStakingTimestamp = 5184000;
  // public minimalStakingTimestamp = 180;
  
  private TEN_POWER_18 = 1000000000000000000;

  // 0x7AE746044fAAF0d1319a948Bc645222ac42df1EF
  private stakingAddress = "0xDCA5fCaE196a774eA153761aC81b5ddEF984D807";
  public formatPriceStandard = formatPriceStandard;
  public formatPrice = formatPrice;

  private entryRequirements = [
    {
      nft: "0x93Ae8aab337E58A6978E166f8132F59652cA6C56",
      type: "genesis",
      number: 0,
    },
    {
      nft: "0x9aaB6e4e017964ec7C0F092d431c314F0CAF6B4B",
      type: "og",
      number: 0,
    },
  ];

  private stakingAddressList = ["0xDCA5fCaE196a774eA153761aC81b5ddEF984D807"];
  public stakingValue: StakingList[] = [
    {
      stakingAddress: "0xDCA5fCaE196a774eA153761aC81b5ddEF984D807",
      active: true,
      amountStake: 0,
      amountWithdraw: 0,
      totalSupplyStaked: 0,
      totalRewardLeftOver: 0,
      rewardRate: 0,
      earned: 0,
      periodFinish: 0,
      rewardsDuration: 0,
      rewardPerToken: 0,
      balanceOf: 0,
      lastUpdateTime: 0,
      APYconvert: 0,
    },
  ] as any;

  timeConverter(UNIX_timestamp: number): string {
    var a = new Date(UNIX_timestamp * 1000);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours() > 9 ? a.getHours() : "0" + a.getHours();
    var min = a.getMinutes() > 9 ? a.getMinutes() : "0" + a.getMinutes();
    // var sec = a.getSeconds();
    var time = date + " " + month + " " + year + " " + hour + ":" + min + ":00";
    // var time = date + " " + month + " " + year + "  8PM GMT+1";
    return time;
  }

  public async claimTicket(stakingAddress: string): Promise<void> {
    console.log(stakingAddress);
    let valid = true;
    let _nft = [] as any;
    let _token = [] as any;
    //   console.log(this.entryRequirements)
    console.log("og balance:" + this.ogBalance.length);
    this.entryRequirements.forEach((element) => {
      // console.log(this.genesisVirtualBalance);
      if (element.type == "trophy") {
        if (element.number > this.throphyBalance.length) {
          valid = false;
        } else {
          for (let index = 0; index < element.number; index++) {
            _nft.push(element.nft);
          }
          _token = _token.concat(this.throphyBalance.slice(0, element.number));
        }
      } else if (element.type == "voyager") {
        if (element.number > this.voyagerBalance.length) {
          valid = false;
        } else {
          for (let index = 0; index < element.number; index++) {
            _nft.push(element.nft);
          }
          _token = _token.concat(this.voyagerBalance.slice(0, element.number));
        }
      } else if (element.type == "og") {
        if (element.number > this.ogBalance.length) {
          valid = false;
        } else {
          for (let index = 0; index < element.number; index++) {
            _nft.push(element.nft);
          }
          _token = _token.concat(this.ogBalance.slice(0, element.number));
        }
      } else if (element.type == "olympics") {
        if (element.number > this.olympicsBalance.length) {
          valid = false;
        } else {
          for (let index = 0; index < element.number; index++) {
            _nft.push(element.nft);
          }
          _token = _token.concat(this.olympicsBalance.slice(0, element.number));
        }
      } else if (element.type == "moon") {
        if (element.number > this.moonBalance.length) {
          valid = false;
        } else {
          for (let index = 0; index < element.number; index++) {
            _nft.push(element.nft);
          }
          _token = _token.concat(this.moonBalance.slice(0, element.number));
        }
      } else if (element.type == "africa") {
        if (element.number > this.africaBalance.length) {
          valid = false;
        } else {
          for (let index = 0; index < element.number; index++) {
            _nft.push(element.nft);
          }
          _token = _token.concat(this.africaBalance.slice(0, element.number));
        }
      } else if (element.type == "whale") {
        if (element.number > this.whaleBalance.length) {
          valid = false;
        } else {
          for (let index = 0; index < element.number; index++) {
            _nft.push(element.nft);
          }
          _token = _token.concat(this.whaleBalance.slice(0, element.number));
        }
      } else if (element.type == "gold") {
        if (element.number > this.goldBalance.length) {
          valid = false;
        } else {
          for (let index = 0; index < element.number; index++) {
            _nft.push(element.nft);
          }
          _token = _token.concat(this.goldBalance.slice(0, element.number));
        }
      } else if (element.type == "platinum") {
        if (element.number > this.platinumBalance.length) {
          valid = false;
        } else {
          for (let index = 0; index < element.number; index++) {
            _nft.push(element.nft);
          }
          _token = _token.concat(this.platinumBalance.slice(0, element.number));
        }
      } else if (element.type == "xnode") {
        if (element.number > this.xnodeBalance.length) {
          valid = false;
        } else {
          for (let index = 0; index < element.number; index++) {
            _nft.push(element.nft);
          }
          _token = _token.concat(this.xnodeBalance.slice(0, element.number));
        }
      } else if (element.type == "genesis") {
        console.log(this.genesisVirtualBalance);
        if (element.number > this.genesisVirtualBalance) {
          // alert(element.number);
          valid = false;
        } else {
          // for (let index = 0; index < element.number; index++) {
          //   _nft.push(element.nft);
          // }
          // _token = _token.concat(this.genesisBalance.slice(0, element.number));
          console.log("pass");
        }
      }
    });
    if (!valid) {
      alert("can not buy ticket");
    } else {
      console.log(_nft);
      console.log(_token);
      this.issueTicket(stakingAddress, _nft, _token);
    }
  }

  public async issueTicket(stakingAddress: string, _nft: [], _token: []) {
    // console.log(_nft);
    // console.log(_token);
    let method = this.$connex.thor
      .account(stakingAddress)
      .method(stakingABI.issueTicketABI);
    const transferClauseIssueTicket = method.asClause(_nft, _token);
    //   console.log(transferClauseIssueTicket);

    // Add the nft approve method here to let the staking contract transfer back the nft
    //   method = this.$connex.thor
    //     .account(this.GENESIS_TOKEN_ADDRESS)
    //     .method(stakingABI.approveForAllABI);
    //   const transferClauseApproveGenesis = method.asClause(stakingAddress, true);
    console.log(this.SPECIAL_TOKEN_ADDRESS);
    method = this.$connex.thor
      .account(this.SPECIAL_TOKEN_ADDRESS)
      .method(stakingABI.approveForAllABI);
    const transferClauseApproveSpecial = method.asClause(stakingAddress, true);

    // eslint-disable-next-line
    const result = await connexTransaction.feeDelegationSignTx(
      this.$connex,
      this.VIP191SERVICE,
      this.VIP191PAYER,
      this.signerAddress,
      [transferClauseApproveSpecial, transferClauseIssueTicket],
      "issue ticket"
    );
  }

  public async addMaxBalance(
    stakingAddress: string,
    index: number
  ): Promise<void> {
    this.stakingValue[index].amountStake = Math.floor(
      this.signerWoVBalance / this.TEN_POWER_18
    );
  }

  public async stakeCall(stakingAddress: string, index: number): Promise<void> {
    let check = false;

    if (this.stakingValue[0].ownerToTicket[0] > 0) {
      check = false;
    } else {
      check = true;
    }

    const result = await stakingFunction.stakeCall(
      this.$connex,
      stakingAddress,
      this.WOV_VIP180,
      this.signerAddress,
      this.stakingValue[0].amountStake,
      check
    );

    // const result = await stakingFunction.stakeCall(
    //   this.$connex,
    //   stakingAddress,
    //   this.WOV_VIP180,
    //   this.signerAddress,
    //   this.stakingValue[0].amountStake
    // );

    console.log(result);

    bus.$emit("stakeCall", result);
    bus.$emit("showToast", true);

    // return result;
  }

  public async getRewardCall(stakingAddress: string): Promise<void> {
    // if (
    //   this.lastStakingTime >
    //   this.lastStakingTime + this.minimalStakingTimestamp
    // ) {
    //   alert("You can claim");
    // } else {
    //   alert("You will loose your claim");
    // }
    const result = await stakingFunction.getRewardCall(
      this.$connex,
      stakingAddress,
      this.signerAddress
    );

    bus.$emit("getRewardCall", result);
    bus.$emit("showToast", true);
    // return result;
  }

  public async withdrawCall(
    stakingAddress: string,
    index: number
  ): Promise<void> {
    const result = await stakingFunction.withdrawCall(
      this.$connex,
      stakingAddress,
      this.signerAddress,
      this.stakingValue[0].amountWithdraw
    );

    bus.$emit("withdrawCall", result);
    bus.$emit("showToast", true);
  }

  public async exitCall(stakingAddress: string): Promise<void> {
    let result = "" as any;
    if (this.stakingValue[0].balanceOf == 0) {
      const method = this.$connex.thor
        .account(stakingAddress)
        .method(stakingABI.closeTicketABI);
      const transferClause = method.asClause();

      result = await connexTransaction.feeDelegationSignTx(
        this.$connex,
        this.VIP191SERVICE,
        this.VIP191PAYER,
        this.signerAddress,
        [transferClause],
        "closeTicket"
      );
    } else {
      const method = this.$connex.thor
        .account(stakingAddress)
        .method(stakingABI.exitABI);
      const transferClause = method.asClause();

      result = await connexTransaction.feeDelegationSignTx(
        this.$connex,
        this.VIP191SERVICE,
        this.VIP191PAYER,
        this.signerAddress,
        [transferClause],
        "exit"
      );
    }
    bus.$emit("exitCall", result);
    bus.$emit("showToast", true);
  }

  public async getFullData(): Promise<void> {
    let address = null;
    if (this.signerAddress != "") {
      address = this.signerAddress;
    }
    const data = await stakingFunction.stakingInfo(
      this.$connex,
      ["0xDCA5fCaE196a774eA153761aC81b5ddEF984D807"],
      address
    );
    //   console.log(data);
    this.stakingValue[0].periodFinish = data.periodFinish;
    this.stakingValue[0].lastUpdateTime = data.lastUpdateTime;
    this.stakingValue[0].rewardsDuration = data.rewardsDuration;
    this.stakingValue[0].rewardRate = data.rewardRate;
    this.stakingValue[0].rewardPerToken = data.rewardPerToken;
    this.stakingValue[0].totalSupplyStaked = data.totalSupplyStaked;
    this.stakingValue[0].totalRewardLeftOver = data.totalRewardLeftOver;
    this.stakingValue[0].earned = data.earned;
    this.stakingValue[0].balanceOf = data.balanceOf;
    this.stakingValue[0].APYconvert = data.APYconvert;
    this.stakingValue[0].ownerToTicket = data.ownerToTicket;

    this.lastStakingTime = await stakingFunction.lastStakeInfo(
      this.$connex,
      ["0xDCA5fCaE196a774eA153761aC81b5ddEF984D807"],
      address
    );
    // console.log(this.lastStakingTime + this.minimalStakingTimestamp);
    // console.log(new Date((this.lastStakingTime + this.minimalStakingTimestamp) * 1000));
  }

  public async created(): Promise<void> {
    this.stakingAddressList.forEach((element, index) => {
      this.stakingValue[index].stakingAddress = element;
    });

    this.getFullData();

    setInterval(() => {
      this.getFullData();
    }, 2000);
  }

  ISODateString(timestamp: number): Date {
    const d = new Date(timestamp * 1000);
    // console.log(d);

    // function pad(n: string|number) {
    //   return n < 10 ? "0" + n : n;
    // }
    return d;
  }
}

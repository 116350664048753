
async function approve(connex : Connex, addressContract: string, signerAddress: string, _spender: string, _value: number): Promise<Connex.Vendor.TxResponse> {

    const ABI = {
        "constant": false,
        "inputs": [{
                "name": "_spender",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [{
            "name": "",
            "type": "bool"
        }],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    }
    // eslint-disable-next-line
    const method = connex.thor.account(addressContract).method(ABI)
    // eslint-disable-next-line
    const transferClause: any[] = [];
    transferClause.push(method.asClause(_spender, _value))

    const signingService = connex.vendor.sign('tx', transferClause)
    signingService
        .signer(signerAddress) // Enforce signer
        .comment('You need to approve the auction contract to handle your VTHO token')
    // eslint-disable-next-line
    const transactionInfo = await signingService.request()
    return transactionInfo
}
async function allowance(connex : Connex, addressContract: string, _owner: string, _spender: string): Promise<number> {
    const ABI = {
        "constant": true,
        "inputs": [{
                "name": "_owner",
                "type": "address"
            },
            {
                "name": "_spender",
                "type": "address"
            }
        ],
        "name": "allowance",
        "outputs": [{
            "name": "",
            "type": "uint256"
        }],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    }
    // eslint-disable-next-line
    const method = connex.thor.account(addressContract).method(ABI)
    const transfer = await method.call(_owner, _spender)
    return transfer.decoded[0];
}
async function balanceOf(connex : Connex, addressContract: string, _address: string): Promise<number> {
    const ABI = {
        "inputs": [{
            "internalType": "address",
            "name": "account",
            "type": "address"
        }],
        "name": "balanceOf",
        "outputs": [{
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }],
        "stateMutability": "view",
        "type": "function"
    }
    // eslint-disable-next-line
    const method = connex.thor.account(addressContract).method(ABI)
    const transfer = await method.call(_address)
    return parseInt(transfer.decoded[0]);
}
async function claim(connex : Connex, addressContract: string, signerAddress: string, tokenIndices: number[]): Promise<Connex.Vendor.TxResponse> {
  
    const ABI = {
        "inputs": [{
            "internalType": "uint256[]",
            "name": "tokenIndices",
            "type": "uint256[]"
        }],
        "name": "claim",
        "outputs": [{
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }],
        "stateMutability": "nonpayable",
        "type": "function"
    }
    const method = connex.thor.account(addressContract).method(ABI)
    // eslint-disable-next-line
    const transferClause: any[] = [];
    for (let index = 0; index < tokenIndices.length; index++) {
        const element:number = tokenIndices[index];
        transferClause.push(method.asClause([element]));
    }
    const signingService = connex.vendor.sign('tx', transferClause)
    signingService
        .signer(signerAddress) // Enforce signer
        .comment('Claiming WoV Token linked to genesis 2021 cards')
    const transactionInfo = await signingService.request();
    return transactionInfo

}
async function claimSpecial(connex : Connex, addressContract: string, signerAddress: string, tokenIndices: number[]): Promise<Connex.Vendor.TxResponse> {

    const ABI = {
        "inputs": [{
            "internalType": "uint256[]",
            "name": "tokenIndices",
            "type": "uint256[]"
        }],
        "name": "claimSpecial",
        "outputs": [{
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }],
        "stateMutability": "nonpayable",
        "type": "function"
    }
    // eslint-disable-next-line
    const method = connex.thor.account(addressContract).method(ABI)
    // eslint-disable-next-line

    const transferClause: any[] = [];
    for (let index = 0; index < tokenIndices.length; index++) {
        const element = tokenIndices[index];
        transferClause.push(method.asClause([element]))
    }
    
    // eslint-disable-next-line
    const signingService = connex.vendor.sign('tx', transferClause)
    signingService
        .signer(signerAddress) // Enforce signer
        .comment('Claiming WoV Token linked to special cards')
    // eslint-disable-next-line
    const transactionInfo = await signingService.request()
    return transactionInfo

}

async function claimAll(connex : Connex, addressContract: string, signerAddress: string, tokenIndicesGenesis: number[], tokenIndicesSpecial: number[]): Promise<Connex.Vendor.TxResponse> {

    const ABI = {
        "inputs": [{
            "internalType": "uint256[]",
            "name": "tokenIndices",
            "type": "uint256[]"
        }],
        "name": "claimGenesis",
        "outputs": [{
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }],
        "stateMutability": "nonpayable",
        "type": "function"
    }
    const method = connex.thor.account(addressContract).method(ABI)
    // eslint-disable-next-line
    const transferClause: any[] = [];
    for (let index = 0; index < tokenIndicesGenesis.length; index++) {
        const element:number = tokenIndicesGenesis[index];
        transferClause.push(method.asClause([element]));
    }

    const ABIspecial = {
        "inputs": [{
            "internalType": "uint256[]",
            "name": "tokenIndices",
            "type": "uint256[]"
        }],
        "name": "claimSpecial",
        "outputs": [{
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }],
        "stateMutability": "nonpayable",
        "type": "function"
    }
    // eslint-disable-next-line
    const methodSpecial = connex.thor.account(addressContract).method(ABIspecial)
    // eslint-disable-next-line
    for (let index = 0; index < tokenIndicesSpecial.length; index++) {
        const element = tokenIndicesSpecial[index];
        transferClause.push(methodSpecial.asClause([element]))
    }
    
    const signingService = connex.vendor.sign('tx', transferClause)
    signingService
        .signer(signerAddress) // Enforce signer
        .comment('Claiming WoV Token')
    const transactionInfo = await signingService.request();
    return transactionInfo

}

async function accumulatedGenesis(connex : Connex, addressContract: string, owner: string, tokenIndex: number): Promise<number> {
    const ABI = {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tokenIndex",
            "type": "uint256"
          }
        ],
        "name": "accumulatedGenesis",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function",
        "constant": true
      }
    // eslint-disable-next-line
    const method = connex.thor.account(addressContract).method(ABI)
    method.caller(owner)
    const transfer = await method.call(tokenIndex)
    return parseInt(transfer.decoded[0]);
}
async function accumulatedSpecial(connex : Connex, addressContract: string, owner: string, tokenIndex: number): Promise<number> {
    const ABI = {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tokenIndex",
            "type": "uint256"
          }
        ],
        "name": "accumulatedSpecial",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
    }
    // eslint-disable-next-line
    const method = connex.thor.account(addressContract).method(ABI)
    method.caller(owner)
    const transfer = await method.call(tokenIndex)
    return parseInt(transfer.decoded[0]);
}

async function totalSupply(connex : Connex, addressContract: string): Promise<number>{
    const ABI = {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      }
    // eslint-disable-next-line
    const method = connex.thor.account(addressContract).method(ABI)
    const transfer = await method.call()
    return transfer.decoded[0];
}
async function increaseAllowance(connex : Connex, addressContract: string, _owner: string, _spender: string): Promise<number> {
    const ABI = {
        "inputs": [
          {
            "internalType": "address",
            "name": "spender",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "addedValue",
            "type": "uint256"
          }
        ],
        "name": "increaseAllowance",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      }
    // eslint-disable-next-line
    const method = connex.thor.account(addressContract).method(ABI)
    const transfer = await method.call(_owner, _spender)
    return transfer.decoded[0];
}


export {
    approve,
    allowance,
    balanceOf,
    claim,
    claimSpecial,
    accumulatedGenesis,
    accumulatedSpecial,
    totalSupply,
    increaseAllowance
}

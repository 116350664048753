import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Connex from '@vechain/connex';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { Chart, registerables } from 'chart.js';
import VComp from '@vue/composition-api';

Chart.register(...registerables);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let connex = '' as any;


declare module 'vue/types/vue' {
  interface Vue {
    $connex: Connex;
  }
}
export const bus = new Vue();

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VComp);

connex = new Connex({
  node: "https://mainnet.veblocks.net",
  network: "main",
});


declare global {
  interface Window {
    connex: any;
  }
}

Vue.prototype.$connex = connex;


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
